<script setup>
// eslint-disable-next-line no-undef
const emit = defineEmits(['previous', 'next', 'reload', 'add']);
const previous = () => { emit('previous') };
const next = () => { emit('next') };
const reload = () => { emit('reload') };
const add = () => { emit('add') };
</script>

<template>
  <div class="input-group">
    <b-button variant="info" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Anterior transacción" @click="previous"><i class="las la-chevron-left label-icon"></i></b-button>
    <b-button variant="info" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Siguiente transacción" @click="next"><i class="las la-chevron-right label-icon"></i></b-button>
    <b-button variant="outline-info" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Recargar" @click="reload"><i class="las la-sync"></i></b-button>
    <b-button variant="info" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Agregar transacción" @click="add"><i class="las la-plus label-icon"></i></b-button>
  </div>
</template>