<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({ params: Object })

const variant = item => {
  let variant = 'info';
  if (['emi', 'car'].includes(item.trim().toLowerCase().substring(0, 3))) {
    variant = 'success';
  } else if (item.trim().toLowerCase().substring(0, 3) == 'anu') {
    variant = 'danger';
  } else if (item.trim().toLowerCase().substring(0, 4) == 'conf') {
    variant = 'primary';
  } else if (['c1', 'c3', 'c4'].includes(item.trim().toLowerCase().substring(0, 2))) {
    variant = 'warning';
  } else if (item.trim().toLowerCase().substring(0, 4) == 'cert') {
    variant = 'success';
  }
  return variant;
}
</script>

<template>
  <div>
    <b-badge :variant="variant(props.params.value)">{{ props.params.value }}</b-badge>
  </div>
</template>
