import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import VueAxios from "vue-axios";
import axios from 'axios';

import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import VueLoading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-loading-overlay/dist/css/index.css';

import { registerLicense } from '@syncfusion/ej2-base';

import { initializeApp } from "firebase/app";

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW35ecXxRRmFUVkNzWQ==');

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MEASUREMENTID
};
initializeApp(firebaseConfig);

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
app.use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vClickOutside)
  .use(VueAxios, axios)
  .use(pinia)
  .use(VueLoading);

router.isReady().then(() =>  { app.mount('#app') });