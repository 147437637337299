<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import Pagination from '@/views/components/Pagination.vue';
import StatusDocument from '@/views/components/StatusDocument';
import ViewToolbar from '@/views/components/ViewToolbar';
import { Modal } from 'bootstrap';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datetimepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días',
      today: 'Hoy'
    }
  }
});

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let pagination12 = ref(null);
let pagination3 = ref(null);
let pagination4 = ref(null);
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirmDelete = ref(null);
let confirmDraft = ref(null);
let confirmLoadStock = ref(null);
let confirmSector = ref(null);
let confirmSectorObj = null;
let confirmC3 = ref(null);
let confirmC4 = ref(null);
let confirmUpload = ref(null);
let confirmConfirm = ref(null);
let data = ref(null);
let documentTypes = ref([]);
let warehouses = ref([]);
let statuses = ref([]);
let sectorReport = ref('');
let data_local = ref({
  id: 0,
  document: '',
  date: new Date(),
  documentTypeId: 0,
  warehouseId: 0,
  originWarehouseId: 0,
  destinationWarehouseId: 0,
  productId: 0,
  description: '',
  comments: '',
  statusId: 'BOR',
  statusName: 'Borrador',
  products: [],
  count3: [],
  count4: [],
  summary12: []
});
let currentPage12 = ref(1);
let currentPage3 = ref(1);
let currentPage4 = ref(1);
let pageSize12 = ref(10);
let pageSize3 = ref(10);
let pageSize4 = ref(10);
const title = "Inventario físico";
const viewParms = computed({
  get() {
    return store.viewParameters.inventories;
  }
});
const items = viewParms.value.breadcrumb ? viewParms.value.breadcrumb : [
  {
    text: "Inventario",
    href: "/",
  },
  {
    text: "Inventarios físicos",
    href: "/stock/operations/inventories",
  },
  {
    text: "Inventario físico",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const apiOdoo = computed({
    get() {
      return store.parameters.apiOdooURL;
    }
});

const documentFunction = computed({
  get() {
    let documentFunction = '';
    const documentType = documentTypes.value.filter(item => item.id == data_local.value.documentTypeId);
    if (documentType.length > 0) {
      documentFunction = documentType[0].functionId;
    }
    return documentFunction;
  }
});

const initialItem12 = computed({
  get() {
    let item = currentPage12.value * pageSize12.value - (pageSize12.value - 1);
    if (item > data_local.value.products.length) {
      item = Math.floor(data_local.value.products.length / pageSize12.value);
    }
    return item;
  }
});

const finalItem12 = computed({
  get() {
    return initialItem12.value + pageSize12.value - 1;
  }
});

const initialItem3 = computed({
  get() {
    let item = currentPage3.value * pageSize3.value - (pageSize3.value - 1);
    if (item > data_local.value.count3.length) {
      item = Math.floor(data_local.value.count3.length / pageSize3.value);
    }
    return item;
  }
});

const finalItem3 = computed({
  get() {
    return initialItem3.value + pageSize3.value - 1;
  }
});

const initialItem4 = computed({
  get() {
    let item = currentPage4.value * pageSize4.value - (pageSize4.value - 1);
    if (item > data_local.value.count4.length) {
      item = Math.floor(data_local.value.count4.length / pageSize4.value);
    }
    return item;
  }
});

const finalItem4 = computed({
  get() {
    return initialItem4.value + pageSize4.value - 1;
  }
});

onMounted(async () => {
  confirmSectorObj = new Modal(confirmSector.value);
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADOINVENTARIOFISICO'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de documento', response.data.message);
    }
    return axios.post(`${api.value}/stock/document_types/inventory`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      documentTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de documento', response.data.message);
    }
    return axios.post(`${api.value}/stock/warehouses/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      warehouses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar bodegas', response.data.message);
    }
    loading.hide();
    getData(id.value);
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de inventario físico'});
    axios
    .post(`${api.value}/stock/inventories/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          if (typeof data.value.date == "string") {
            data.value.date = new Date(data.value.date);
          }
          data_local.value = JSON.parse(JSON.stringify(data.value));
          if (data_local.value.products == null) {
            data_local.value.products = [];
          }
          if (data_local.value.count3 == null) {
            data_local.value.count3 = [];
          }
          if (data_local.value.count4 == null) {
            data_local.value.count4 = [];
          }
          pagination12.value.setTotalItems(data_local.value.products.length);
          pagination3.value.setTotalItems(data_local.value.count3.length);
          pagination4.value.setTotalItems(data_local.value.count4.length);
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar inventario físico', response.data.message);
      }
      return axios.post(`${api.value}/stock/inventories/summary_count12`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        inventoryId: id
      });
    })
    .then(response => {
      if (response.data.success) {
        data_local.value.summary12 = response.data.data;
      } else {
        dialog.value.show('error', 'Error al cargar resumen de conteo 1 y 2', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar inventario físico ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'inventories'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    if (data_local.value.products == null) {
      data_local.value.products = [];
    }
    if (data_local.value.count3 == null) {
      data_local.value.count3 = [];
    }
    if (data_local.value.count4 == null) {
      data_local.value.count4 = [];
    }
    pagination12.value.setTotalItems(data_local.value.products.length);
    pagination3.value.setTotalItems(data_local.value.count3.length);
    pagination4.value.setTotalItems(data_local.value.count4.length);
    editing.value = false;
  }
};

const pageSizeChange12 = ps => {
  currentPage12.value = 1;
  pageSize12.value = ps;
}

const setPage12 = page => {
  currentPage12.value = page;
}

const pageSizeChange3 = ps => {
  currentPage3.value = 1;
  pageSize3.value = ps;
}

const setPage3 = page => {
  currentPage3.value = page;
}

const pageSizeChange4 = ps => {
  currentPage4.value = 1;
  pageSize4.value = ps;
}

const setPage4 = page => {
  currentPage4.value = page;
}

const openProduct = productId => {
  store.viewParameters.products.breadcrumb = [
    {
      text: "Inventario",
      href: "/",
    },
    {
      text: "inventarios físicos",
      href: "/stock/operations/inventories",
    },
    {
      text: data_local.value.document,
      href: `/stock/operations/inventories/${id.value}`,
    },
    {
      text: "Producto",
      active: true,
    }
  ];
  router.push({name: 'product', params: {id: `${productId}`}})
}

const validateForm = () => {
  let message = '';
  if (!data_local.value.documentTypeId || data_local.value.documentTypeId == 0) {
    message = 'Debe seleccionar un tipo de documento';
  } else if (['-', '>'].includes(documentFunction.value) && (!data_local.value.originWarehouseId || data_local.value.originWarehouseId == 0)) {
    message = 'Debe seleccionar una bodega';
  }
  if (message != '') {
    dialog.value.show('error', 'Error al guardar transacción', message);
  }
  return (message == '');
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de inventario'});
    data_local.value.id = id.value;
    data_local.value.documentTypeFunction = documentFunction.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/stock/inventories/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value == 0) {
          router.push({
            name: 'inventory',
            params: {id: response.data.data.id}
          });
          getData(response.data.data.id);
        }
      } else {
        dialog.value.show('error', 'Error interno al actualizar inventario físico', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar inventario físico ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando inventario físico'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/transactions/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Inventario físico fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar inventario físico ${id.value}`, error.message);
  });
};

const changeStatus = async (title, statusId, message) => {
  if (['C3', 'C4'].includes(statusId)) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => "Generando conteo 3"});
    const parms = {
      inventoryId: id.value,
      token: store.userInfo.token,
      userId: store.userInfo.userId
    };
    const count = statusId == 'C3' ? '3' : '4';
    axios
    .post(`${api.value}/stock/inventories/generate_count${count}`, parms)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        setStatus(title, statusId, message);
      } else {
        dialog.value.show('error', `Error interno al generar conteo ${count}`, response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error', `Error al generar conteo ${count}`, error.message);
    });
  } else if (statusId == 'CAR') {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => "Cargando conteo 4 a Odoo"});
    const parms = {
      locationId: data_local.value.originWarehouseId,
      products: data_local.value.count4.filter(p => p.stock != p.finalQuantity),
      token: store.userInfo.token,
      userId: store.userInfo.userId
    };
    // console.log(apiOdoo.value);
    // const apiOdoovalue = 'http://localhost:8080'
    // console.log(`${apiOdoovalue}/v1/odoo/update_stock_odoo`);
    // console.log(parms);
    axios
    .post(`${apiOdoo.value}/v1/odoo/update_stock_odoo`, parms)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        setStatus(title, statusId, message);
      } else {
        dialog.value.show('error', `Error interno al cargar conteo 4 a Odoo`, response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error', `Error al al cargar conteo 4 a Odoo`, error.message);
    });
  } else {
    await setStatus(title, statusId, message);
  }
}

const setStatus = async (title, statusId, message) => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => title});
  const parms = {
    id: id.value,
    statusId,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/transactions/change_status`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialog.value.show('success', 'Inventario físico actualizado', message);
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error interno al actualizar inventario físico', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al actualiar inventario físico ${id.value}`, error.message);
  });
};

const previous = () => {
  console.log('anterior');
}

const next = () => {
  console.log('siguiente');
}

const add = () => {
  router.push({
    name: 'inventory',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    document: '',
    date: new Date(),
    documentTypeId: 0,
    warehouseId: 0,
    originWarehouseId: 0,
    destinationWarehouseId: 0,
    productId: 0,
    description: '',
    comments: '',
    statusId: 'BOR',
    statusName: 'Borrador',
    products: [],
    count3: [],
    count4: [],
    summary12: []
  };
  editing.value = true;
};

const b64toBlob = (b64Data, contentType, sliceSize) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const generateDifferences = () => {
  if (['C4', 'CAR'].includes(data_local.value.statusId)) {
    sectorReport.value = ''
  }
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => "Generando informe de diferencias"});
  const parms = {
    inventoryId: id.value,
    statusId: data_local.value.statusId,
    sector: sectorReport.value,
    token: store.userInfo.token,
    userId: store.userInfo.userId
  };
  axios
  .post(`${api.value}/stock/inventories/count_comparison_report`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(b64toBlob(response.data.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
      if (data_local.value.statusId == 'CAR') {
        link.download = `Diferencias para conteo cargado a Odoo - ${data_local.value.description}.xlsx`;
      } else {
        link.download = `Diferencias para conteo ${(data_local.value.statusId == 'C3' ? '3' : '4')} - ${data_local.value.description}.xlsx`;
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dialog.value.show('error', 'Error interno al generar reporte comparativo', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al generar reporte comparativo`, error.message);
  });
}

const loadStock = () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => "Cargando existencias desde Odoo"});
  const parms = {
    locationId: data_local.value.originWarehouseId,
    products: data_local.value.count4,
    token: store.userInfo.token,
    userId: store.userInfo.userId
  };
  // const apivalue = 'http://localhost:8080/v2';
  // console.log(`${api.value}/odoo/get_stock_products`);
  axios
  .post(`${api.value}/odoo/get_stock_products`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error interno al cargar existencias desde Odoo', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al cargar existencias desde Odoo`, error.message);
  });
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-2">
                        <div v-if="!editing" class="col-2" style="width: 180px;">
                          <ViewToolbar @previous="previous" @next="next" @reload="getData(id)" @add="add" />
                        </div>
                        <div class="col p-1">
                          <StatusDocument :statusId="data_local.statusId" :statusName="data_local.statusName" :statuses="statuses"/>
                        </div>
                        <div class="col input-group justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="success" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Confirmar inventario" @click="confirmConfirm.show('Confirme', '¿Está seguro de confirmar este inventario?')"><i class="las la-check-circle label-icon"></i> Confirmar</b-button>
                          <b-button v-if="!editing && ['BOR', 'C1-2', 'C3', 'C4'].includes(data_local.statusId)" variant="primary" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="danger" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Eliminar transacción" @click="confirmDelete.show('Confirme', '¿Está seguro de eliminar este inventario?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                          <b-button v-if="!editing && data_local.statusId != 'BOR'" variant="info" class="btn-label waves-effect waves-light" style="margin-left: 2px;" v-b-tooltip.hover title="Cambiar a borrador" @click="confirmDraft.show('Confirme', '¿Está seguro de cambiar este inventario a borrador?')"><i class="las la-trash label-icon"></i> Cambiar a borrador</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-2">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.document" placeholder="Número de documento" :disabled="true"/>
                          <label for="role">Número de documento</label>
                        </div>
                      </div>
                      <div class="input-group col justify-content-end">
                        <b-button v-if="!editing && data_local.statusId == 'C4'" variant="warning" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Cargar exstencias desde Odoo" @click="confirmLoadStock.show('Confirme', '¿Está seguro de cargar existencias desde Odoo?')"><i class="las la-download label-icon"></i> Cargar existencias</b-button>
                        <b-button v-if="!editing && data_local.statusId == 'C3'" variant="info" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Reporte de diferencias" @click="confirmSectorObj.show()"><i class="las la-file-excel label-icon"></i> Diferencias</b-button>
                        <b-button v-if="!editing && ['C4', 'CAR'].includes(data_local.statusId)" variant="info" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Reporte de diferencias" @click="generateDifferences"><i class="las la-file-excel label-icon"></i> Diferencias</b-button>
                        <b-button v-if="!editing && data_local.statusId == 'C1-2'" variant="warning" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Cambiar inventario a conteo 3" @click="confirmC3.show('Confirme', '¿Está seguro de cambiar el inventario a conteo 3?')"><i class="las la-window-close label-icon"></i> Conteo 3</b-button>
                        <b-button v-if="!editing && data_local.statusId == 'C3'" variant="warning" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Cambiar inventario a conteo 4" @click="confirmC4.show('Confirme', '¿Está seguro de cambiar el inventario a conteo 4?')"><i class="las la-window-close label-icon"></i> Conteo 4</b-button>
                        <b-button v-if="!editing && data_local.statusId == 'C4'" variant="success" class="btn-label waves-effect waves-light h-75" style="margin-left: 2px;" v-b-tooltip.hover title="Cargar a Odoo" @click="confirmUpload.show('Confirme', '¿Está seguro de cargar el conteo 4 final a Odoo?')"><i class="las la-upload label-icon"></i> Subir</b-button>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col">
                        <TextBoxComponent v-model:value="data_local.description" floatLabelType="Auto" placeholder="Descripción" :enabled="editing"/>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-2 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.documentTypeId"
                          floatLabelType="Auto"
                          placeholder="Tipo de documento"
                          highlight=true
                          :dataSource="documentTypes"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col-3 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.originWarehouseId"
                          floatLabelType="Auto"
                          placeholder="Bodega origen"
                          highlight=true
                          :dataSource="warehouses"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col me-1">
                        <DateTimePickerComponent v-model:value="data_local.date" floatLabelType="Auto" placeholder="Fecha" locale="es-GT" :enabled="editing" width="250"/>
                      </div>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-uccess bg-light mt-2 mb-3" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#products" role="tab">
                          Conteo 1 y 2
                        </a>
                      </li>
                      <li v-show="['C3', 'C4', 'CAR'].includes(data_local.statusId)" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#count3" role="tab">
                          Conteo 3
                        </a>
                      </li>
                      <li v-show="['C4', 'CAR'].includes(data_local.statusId)" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#count4" role="tab">
                          Conteo 4
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#summary12" role="tab">
                          Resumen conteo 1 y 2
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#other-info" role="tab">
                          Información adicional
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="products" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Sector</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Conteo</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium">Responsable</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad</div>
                            </div>
                            <div v-for="(line, index) in data_local.products" :key="line.id">
                              <div v-if="(index + 1) >= initialItem12 && (index + 1) <= finalItem12" class="row">
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div class="col p-1 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.productCode }} | {{ line.productName }}
                                  </b-button>
                                </div>
                                <div class="col-1 bg-white border-start">
                                  <TextBoxComponent v-model:value="line.sector" placeholder="Sector" :enabled="editing && data_local.statusId == 'C1-2'"/>
                                </div>
                                <div class="col-1 bg-white border-start">
                                  <TextBoxComponent v-model:value="line.count" placeholder="Conteo" :enabled="editing && data_local.statusId == 'C1-2'"/>
                                </div>
                                <div class="col-2 bg-white border-start">
                                  <TextBoxComponent v-model:value="line.responsible" placeholder="Responsable" :enabled="editing && data_local.statusId == 'C1-2'"/>
                                </div>
                                <div class="col-2 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.quantity"
                                    format="N0"
                                    :enabled="editing && data_local.statusId == 'C1-2'"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0"
                                    cssClass="numericalign"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row border-top justify-content-between">
                              <div class="col" v-show="data_local.products.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination12" :pageSize="pageSize12" :currentPage="currentPage12" @pageSizeChange="pageSizeChange12" @pageChange="setPage12"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="['C3', 'C4', 'CAR'].includes(data_local.statusId)" class="tab-pane px-2" id="count3" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Sector</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Responsable 1</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad 1</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Responsable 2</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad 2</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Diferencia</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad final</div>
                            </div>
                            <div v-for="(line, index) in data_local.count3" :key="line.id">
                              <div v-if="(index + 1) >= initialItem3 && (index + 1) <= finalItem3" class="row">
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div class="col p-1 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.productCode }} | {{ line.productName }}
                                  </b-button>
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle">
                                  {{ line.sector }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle">
                                  {{ line.responsible }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.quantity.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{ line.responsible2 }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.quantity2.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.difference.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.finalQuantity"
                                    format="N0"
                                    :enabled="editing && data_local.statusId == 'C3'"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0"
                                    cssClass="numericalign"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row border-top justify-content-between">
                              <div v-show="data_local.count3.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination3" :pageSize="pageSize3" :currentPage="currentPage3" @pageSizeChange="pageSizeChange3" @pageChange="setPage3"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="['C4', 'CAR'].includes(data_local.statusId)" class="tab-pane px-2" id="count4" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Costo</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Existencia</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Diferencia</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Valor diferencia</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad final</div>
                            </div>
                            <div v-for="(line, index) in data_local.count4" :key="line.id">
                              <div v-if="(index + 1) >= initialItem4 && (index + 1) <= finalItem4" class="row">
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div class="col p-1 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.productCode }} | {{ line.productName }}
                                  </b-button>
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.cost.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 4,
                                      maximumFractionDigits: 4
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.quantity.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.stock.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    line.difference.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end">
                                  {{
                                    (line.difference * line.cost).toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                                </div>
                                <div class="col-1 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.finalQuantity"
                                    format="N0"
                                    :enabled="editing && data_local.statusId == 'C4'"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0"
                                    cssClass="numericalign"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row border-top justify-content-between">
                              <div v-show="data_local.count4.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination4" :pageSize="pageSize4" :currentPage="currentPage4" @pageSizeChange="pageSizeChange4" @pageChange="setPage4"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="summary12" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium">Sector</div>
                              <div class="col-3 p-2 border-start fs-12 bg-light fw-medium">Responsable 1</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Líneas 1</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Unidades 1</div>
                              <div class="col-3 p-2 border-start fs-12 bg-light fw-medium ">Responsable 2</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Líneas 2</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Unidades 2</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Diferencia</div>
                            </div>
                            <div v-for="line in data_local.summary12" :key="line.sector">
                              <div class="row">
                                <div class="col-1 ps-2 pt-1 bg-white border-start">
                                  {{ line.sector }}
                                </div>
                                <div class="col-3 ps-2 pt-1 bg-white border-start">
                                  {{ line.responsible1 }}
                                </div>
                                <div class="col-1 p-1 bg-white text-end">
                                  {{
                                    line.lines1.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 p-1 bg-white text-end">
                                  {{
                                    line.quantity1.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-3 ps-2 pt-1 bg-white border-start">
                                  {{ line.responsible2 }}
                                </div>
                                <div class="col-1 p-1 bg-white text-end">
                                  {{
                                    line.lines2.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 p-1 bg-white text-end">
                                  {{
                                    line.quantity2.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                                <div class="col-1 p-1 bg-white text-end">
                                  {{
                                    line.difference.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="other-info" role="tabpanel">
                        <div class="mb-3">
                          <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'stockTransactions'});"/>
    <Confirm ref="confirmDelete" @accept="remove"/>
    <Confirm ref="confirmDraft" @accept="changeStatus('Cambiando a borrador', 'BOR', `Inventario ${data_local.document} ha sido cambiado a borrador`)"/>
    <Confirm ref="confirmC3" @accept="changeStatus('Cambiando a conteo 3', 'C3', `Inventario ${data_local.document} ha sido actualizado a conteo 3`)"/>
    <Confirm ref="confirmC4" @accept="changeStatus('Cambiando a conteo 4', 'C4', `Inventario ${data_local.document} ha sido actualizado a conteo 4`)"/>
    <Confirm ref="confirmLoadStock" @accept="loadStock"/>
    <Confirm ref="confirmUpload" @accept="changeStatus('Cargando a Odoo', 'CAR', 'Conteo 4 final ha sido cargado a odoo')"/>
    <Confirm ref="confirmConfirm" @accept="changeStatus('Confirmando inventario', 'C1-2', `Inventario ${data_local.document} ha sido confirmado`)"/>
    <div
    class="modal fade"
    ref="confirmSector"
    id="confirmSector"
    tabindex="-1"
    aria-labelledby="confirmSectorLabel"
    aria-hidden="true"
    style="display: none"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="confirmDialogLabel">Sector</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <i class="las la-question text-danger fs-1 col-1"></i>
            <p class="col mt-2 ms-3">Indique sector a reportar:</p>
            <TextBoxComponent v-model:value="sectorReport" placeholder="Escriba el sector a reportar, en blanco para listar todos los sectores"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="generateDifferences">
            Aceptar
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}

.numericalign .e-numerictextbox {
  text-align: right;
}
</style>