<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  order: 0
});
const title = "Categoría de productos";
const items = [
  {
    text: "Catálogos",
    href: "/",
  },
  {
    text: "Categorías de productos",
    href: "/catalogs/categories",
  },
  {
    text: "Categoría de productos",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  getData(id.value);
});

const getData = async (id) => {
  if (id == 0) {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando categoría de producto'});
    await axios
    .post(`${api.value}/products/categories/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error al cargar categoría de producto', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar categoría de producto ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == 0) {
    router.push({name: 'categories'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar lista', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar lista', 'Debe especificar una descripción');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando categoría de producto'});
    data_local.value.id = id.value;
    data_local.value.code = data_local.value.code.toUpperCase();
    data_local.value.order = Math.abs(data_local.value.order);
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/products/categories/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'category',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar categoría de producto ${id.value}`, error.message);
    });
  } 
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando categoría de producto'});
  await axios
  .post(`${api.value}/products/categories/delete`, {id: id.value, context: store.userInfo})
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Categoría de producto fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar categoría de producto ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'category',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    order: 0
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar parámetro" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar categoría de producto" @click="confirm.show('Confirme', '¿Está seguro de eliminar esta categoría de producto?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary text-uppercase" :class="{'bg-light': !editing, 'bg-white': editing}" id="code" v-model="data_local.code" placeholder="Código" :disabled="!editing"/>
                      <label for="code">Código</label>
                    </div>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary" :class="{'bg-light': !editing, 'bg-white': editing}" id="name" v-model="data_local.name" placeholder="Descripción" :disabled="!editing"/>
                      <label for="name">Descripción</label>
                    </div>
                    <div class="form-floating">
                      <input type="number" class="form-control rounded-0 border-0 border-bottom border-secondary" :class="{'bg-light': !editing, 'bg-white': editing}" id="value" v-model="data_local.order" placeholder="Orden" :disabled="!editing" min="0" max="999999" />
                      <label for="value">Orden</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'categories'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>
