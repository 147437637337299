import store from "@/state/store";
import { useFastWay } from "@/state/modules/fastway";
import Login from '@/views/auth/Login';
import ValidateToken from '@/views/auth/ValidateToken';
import Home from '@/views/Home.vue';
import Parameters from '@/views/infra/parameters/Parameters.vue';
import Parameter from '@/views/infra/parameters/Parameter.vue';
import Lists from '@/views/infra/lists/Lists.vue';
import List from '@/views/infra/lists/List.vue';
import Roles from '@/views/infra/roles/Roles.vue';
import Role from '@/views/infra/roles/Role.vue';
import Users from '@/views/infra/users/Users.vue';
import User from '@/views/infra/users/User.vue';
import Locations from '@/views/infra/locations/Locations';
import Location from '@/views/infra/locations/Location';
import MeasureUnits from '@/views/stock/measureUnits/MeasureUnits';
import MeasureUnit from '@/views/stock/measureUnits/MeasureUnit';
import Warehouses from '@/views/stock/warehouses/Warehouses';
import Warehouse from '@/views/stock/warehouses/Warehouse';
import ProductCategories from '@/views/stock/productCategories/ProductCategories';
import ProductCategory from '@/views/stock/productCategories/ProductCategory';
import Products from '@/views/stock/products/Products';
import Product from '@/views/stock/products/Product';
import Inventories from '@/views/stock/inventories/Inventories';
import Inventory from '@/views/stock/inventories/Inventory';

export default [
  //
  //  INFRAESTRUCTURE
  //  ===============
  //
  {
    path: "/infra/parameters",
    name: "parameters",
    meta: { title: "Parámetros", authRequired: true },
    component: Parameters
  },
  {
    path: "/infra/parameter/:id",
    name: "parameter",
    meta: { title: "Parámetro", authRequired: true },
    component: Parameter
  },
  {
    path: "/infra/lists",
    name: "lists",
    meta: { title: "Listas", authRequired: true },
    component: Lists
  },
  {
    path: "/infra/lists/:id",
    name: "list",
    meta: { title: "Lista", authRequired: true },
    component: List
  },
  {
    path: "/infra/roles",
    name: "roles",
    meta: { title: "Roles", authRequired: true },
    component: Roles
  },
  {
    path: "/infra/roles/:id",
    name: "role",
    meta: { title: "Rol", authRequired: true },
    component: Role
  },
  {
    path: "/infra/users",
    name: "users",
    meta: { title: "Usuarios", authRequired: true },
    component: Users
  },
  {
    path: "/infra/users/:id",
    name: "user",
    meta: { title: "Usuario", authRequired: true },
    component: User
  },
  {
    path: "/infra/locations",
    name: "locations",
    meta: { title: "Ubicaciones", authRequired: true },
    component: Locations
  },
  {
    path: "/infra/locations/:id",
    name: "location",
    meta: { title: "Ubicación", authRequired: true },
    component: Location
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta:  { title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["fastway/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/validate_access/:token",
    name: "validateAccess",
    component: ValidateToken
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Tablero",
      authRequired: true,
    },
    component: Home,
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const fwStore = useFastWay();
        fwStore.userInfo = {
          id: 0,
          uid: 0,
          userId: 0,
          login: '',
          userName: '',
          displayName: '',
          image: '',
          about: '',
          photoURL: '',
          status: '',
          userRole: '',
          organizationId: 0,
          organizationName: '',
          token: ''
        };
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom }
        );
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  //
  //  STOCK
  //  =====
  //
  {
    path: "/stock/catalogs/measure_units",
    name: "measureUnits",
    meta: { title: "Unidades de medida", authRequired: true },
    component: MeasureUnits
  },
  {
    path: "/stock/catalogs/measure_units/:id",
    name: "measureUnit",
    meta: { title: "Unidad de medida", authRequired: true },
    component: MeasureUnit
  },
  {
    path: "/stock/catalogs/warehouses",
    name: "warehouses",
    meta: { title: "Bodegas", authRequired: true },
    component: Warehouses
  },
  {
    path: "/stock/catalogs/warehouses/:id",
    name: "warehouse",
    meta: { title: "Bodega", authRequired: true },
    component: Warehouse
  },
  {
    path: "/stock/catalogs/product_categories",
    name: "productCategories",
    meta: { title: "Categorías", authRequired: true },
    component: ProductCategories
  },
  {
    path: "/stock/catalogs/product_categories/:id",
    name: "productCategory",
    meta: { title: "Categoria", authRequired: true },
    component: ProductCategory
  },
  {
    path: "/stock/catalogs/products",
    name: "products",
    meta: { title: "Productos", authRequired: true },
    component: Products
  },
  {
    path: "/stock/catalogs/products/:id",
    name: "product",
    meta: { title: "Producto", authRequired: true },
    component: Product
  },
  {
    path: "/stock/operations/inventories",
    name: "inventories",
    meta: { title: "Inventarios físicos", authRequired: true },
    component: Inventories
  },
  {
    path: "/stock/operations/inventories/:id",
    name: "inventory",
    meta: { title: "Inventario físico", authRequired: true },
    component: Inventory
  }
];