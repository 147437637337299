<script setup>
import { ref, toRefs, computed } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
    pageSize: {
        type: Number,
        default: 15
    },
    pageSizes: {
        type: Array,
        default: () => [10, 15, 25, 50, 100, 500, 1000, 5000, 10000]
    },
    currentPage: {
        type: Number,
        default: 1
    },
    pagesShown: {
        type: Number,
        default: 10
    }
})

const { pageSize, pageSizes, currentPage, pagesShown } = toRefs(props);
let totalItems = ref(0);
let selectedPageSize = ref(10);
selectedPageSize.value = pageSize.value;

let totalPages = ref(1);
if (totalItems.value > 0) {
    totalPages.value = Math.ceil(totalItems.value / pageSize.value);
}

const pageList = computed({
    get() {
        let list = [{number: 1, label: '1'}];
        let startPage = currentPage.value - Math.floor(pagesShown.value / 2) + 2;
        if (startPage < 2 || totalPages.value <= pagesShown.value) {
            startPage = 2;
        }
        let endPage = startPage + pagesShown.value - 3;
        if (endPage >= totalPages.value) {
            endPage = totalPages.value - 1;
        }
        if (endPage > pagesShown.value && (endPage - startPage) < pagesShown.value) {
            startPage = endPage - pagesShown.value + 3;
        }
        if (startPage > 2 && totalPages.value > pagesShown.value) {
            list.push({number: 0, label: '...'});
        }
        for (let i = startPage; i < totalPages.value && i <= endPage; i++) {
            list.push({number: i, label: i.toString()});
        }
        if (list[list.length - 1].number < (totalPages.value - 1)) {
            list.push({number: 999999999, label: '...'});
        }
        if (totalPages.value > 1) {
            list.push({number: totalPages.value, label: totalPages.value.toString()});
        }
        return list;
    }
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['pageSizeChange', 'pageChange']);

const pageSizeChange = () => {
    if (totalItems.value > 0) {
        totalPages.value = Math.ceil(totalItems.value / selectedPageSize.value);
    }
    emit('pageSizeChange', selectedPageSize.value);
}

const setPage = page => {
    emit('pageChange', page);
}

const _setTotalItems = (items) => {
    totalItems.value = items;
    if (totalItems.value > 0) {
        totalPages.value = Math.ceil(totalItems.value / selectedPageSize.value);
    }
}

// eslint-disable-next-line no-undef
defineExpose({setTotalItems: _setTotalItems});
</script>

<template>
    <div class="d-flex pt-2">
        <div class="mt-1 me-3">
            <select class="form-select form-select-sm bg-light rounded-0 border-0 border-bottom border-secondary" list="pageOptions" v-model="selectedPageSize" @change="pageSizeChange">
                <option v-for="page in pageSizes" :key="page" :value="page">{{ page }} items por página</option>
            </select>
        </div>
        <div>
            <ul class="pagination">
            <li class="page-item" :class="{disabled: currentPage <= 1}">
                <a class="page-link" href="#" aria-label="Previous" @click="setPage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li
                v-for="page in pageList"
                :key="page.number"
                class="page-item" 
                :class="{active: currentPage == page.number, disabled: page.label == '...'}"
                @click="setPage(page.number)"
            >
                <a class="page-link" href="#">{{ page.label }}</a>
            </li>
            <li class="page-item" :class="{disabled: currentPage >= totalPages}">
                <a class="page-link" href="#" aria-label="Next" @click="setPage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
            </ul>
        </div>
    </div>
</template>