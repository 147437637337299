<template>
    <footer class="footer">
        <BContainer fluid>
            <BRow>
                <BCol col sm="6">
                    {{ new Date().getFullYear() }} © SBG
                </BCol>
                <BCol col sm="6">
                    <div class="text-sm-end d-none d-sm-block">
                        Design & Develop by Dynamic Development Studio
                    </div>
                </BCol>
            </BRow>
        </BContainer>
    </footer>
</template>
