<script setup>
import { onMounted } from "vue";
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { useFastWay } from "@/state/modules/fastway";
import axios from 'axios';

const store = useFastWay();
const db = getFirestore();

onMounted(async () => {
  onSnapshot(doc(db, 'parameters', 'connection'), snap => {
    store.parameters = snap.data();
    if (store.userInfo.token != '') {
      const context = {userId: store.userInfo.userId, token: store.userInfo.token};
      axios
      .post(`${store.parameters.apiURL}/parameters/list`, context)
      .then(response => {
        if (response.data.success) {
          if (response.data.data) {
            store.parametersFW = response.data.data;
          }
        }
        return axios
          .post(`${store.parameters.apiURL}/infra/regions/countries`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.countries = response.data.data;
        }
        return axios
          .post(`${store.parameters.apiURL}/infra/regions/states`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.states = response.data.data;
        }
        return axios
          .post(`${store.parameters.apiURL}/infra/regions/cities`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.cities = response.data.data;
        }
      });
    }
  });
});
</script>

<template>
  <router-view></router-view>
</template>
 