<script setup>
import { onMounted, computed } from "vue";
import { useFastWay } from "@/state/modules/fastway";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';

const store = useFastWay();
const router = useRouter();
const route = useRoute();

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(() => {
  if (Object.prototype.hasOwnProperty.call(route, 'params')) {
    if (Object.prototype.hasOwnProperty.call(route.params, 'token')) {
      const token = route.params.token;
      if (token.value == '') {
        router.push({name: 'login'});
      } else {
        axios
          .post(`${api.value}/users/validate_token`, { token })
          .then(response => {
            if (response.data.success) {
              if (response.data.data.token == '') {
                router.push({name: 'login'});
              } else { 
                store.userInfo = {
                  uid: response.data.data.id,
                  userId: response.data.data.id,
                  login: response.data.data.login,
                  userName: response.data.data.login,
                  displayName: response.data.data.name,
                  organizationId: response.data.data.organizationId,
                  organizationName: response.data.data.organizationName,
                  token: response.data.data.token,
                };
                router.push({name: 'home'});
              }
            } else {
              router.push({name: 'login'});
            }
          })
          .catch(() => {
            router.push({name: 'login'});
          });
      }
    } else {
      router.push({name: 'login'});
    }
  } else {
    router.push({name: 'login'});
  }
});
</script>

<template>
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <BCard no-body class="overflow-hidden">
                            <BRow class="g-0">
                                <BCol lg="6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <router-link to="/" class="d-block">
                                                    <img src="@/assets/images/logo-light.png" alt="" height="18">
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </BCol>

                                <BCol lg="6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                          <h5 class="text-primary">Bienvenido</h5>
                                          <p class="text-muted">Validando acceso, un momento por favor</p>
                                        </div>

                                        <div class="mt-4">
                                          <span class="d-flex align-items-center">
                                            <span class="spinner-border flex-shrink-0" role="status">
                                              <span class="visually-hidden">Validando acceso...</span>
                                            </span>
                                            <span class="flex-grow-1 ms-2">
                                              Validando acceso...
                                            </span>
                                          </span>
                                        </div>
                                    </div>
                                </BCol>
                            </BRow>
                        </BCard>
                    </BCol>
                </BRow>
            </BContainer>
        </div>

        <footer class="footer">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <div class="text-center">
                            <p class="mb-0">&copy; {{ new Date().getFullYear() }} FastWay. Elaborado con <i
                                    class="mdi mdi-heart text-danger"></i> por Dynamic Development Studio</p>
                        </div>
                    </BCol>
                </BRow>
            </BContainer>
        </footer>

        <Dialog ref="dialog"/>
    </div>
</template>