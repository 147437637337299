<script setup>
import { ref, computed } from "vue";
import { useFastWay } from "@/state/modules/fastway";
import axios from 'axios';
import Dialog from '@/views/components/Dialog.vue';

const store = useFastWay();
let email = ref('');
let loginEnabled = ref(true);
let accessSent = ref(false);
let dialog = ref(null);

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const sendMagicLink = async () => {
  loginEnabled.value = false;
  accessSent.value = false;
  await axios
  .post(`${api.value}/users/send_access_token`, { email: email.value })
  .then(response => {
    if (response.data.success) {
      if (response.data.data.token == '') {
        dialog.value.show('error', 'Acceso denegado', 'Usuario no registrado');
      } else { 
        accessSent.value = true;
      }
    } else {
      dialog.value.show('error', 'Acceso denegado', response.data.message);
    }
    loginEnabled.value = true;
  })
  .catch(error => {
    loginEnabled.value = true;
    dialog.value.show('error', 'Acceso denegado', error.message);
  });
};

</script>

<template>
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <BCard no-body class="overflow-hidden">
                            <BRow class="g-0">
                                <BCol lg="6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <router-link to="/" class="d-block">
                                                    <img src="@/assets/images/logo-light.png" alt="" height="18">
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </BCol>

                                <BCol v-if="accessSent" lg="6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                            <h5 class="text-primary">Enlace enviado</h5>
                                            <p class="text-muted">
                                              Se envió enlace de acceso a <b>{{ email }}</b>.
                                              <br>
                                              Por favor siga las instrucciones en el correo.
                                              <br><br>
                                              Si ya ha accedido puede cerrar esta pestaña.
                                            </p>
                                        </div>

                                        <div class="mt-4">
                                            <form>
                                                <div class="mt-4">
                                                    <BButton variant="success" class="w-100" @click="accessSent = false">
                                                      Enviar enlace de nuevo
                                                    </BButton>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </BCol>

                                <BCol v-else lg="6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                            <h5 class="text-primary">Bienvenido</h5>
                                            <p class="text-muted">Especifique dirección de correo electrónico para acceder.</p>
                                        </div>

                                        <div class="mt-4">
                                            <form>
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Correo electrónico</label>
                                                    <input type="text" class="form-control" id="email" placeholder="Especifique correo electrónico autorizado" v-model=email>
                                                </div>

                                                <div class="mt-4">
                                                    <BButton variant="success" class="w-100" @click="sendMagicLink">
                                                      Enviar enlace de acceso
                                                    </BButton>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </BCol>
                            </BRow>
                        </BCard>
                    </BCol>
                </BRow>
            </BContainer>
        </div>

        <footer class="footer">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <div class="text-center">
                            <p class="mb-0">&copy; {{ new Date().getFullYear() }} FastWay. Elaborado con <i
                                    class="mdi mdi-heart text-danger"></i> por Dynamic Development Studio</p>
                        </div>
                    </BCol>
                </BRow>
            </BContainer>
        </footer>

        <Dialog ref="dialog"/>
    </div>
</template>