<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import {Query} from '@syncfusion/ej2-data';

const router = useRouter();
const store = useFastWay();
const loader = useLoading();
let query = ref(new Query().select(['parameter', 'id']).take(50));
let dialog = ref(null);
let gridColumnApi = null;
let loaded = ref(false);
let data = ref([]);
let parameters = ref([]);
const title = "Parámetros";
const items = [
  {
    text: "Infraestructura",
    href: "/",
  },
  {
    text: "Parámetros",
    active: true,
  },
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const linkRenderer = params => {
  return `<a href="/infra/parameter/${params.data.id}" role="button" @click="console.log('ya')">${params.value}</a>`;
}

const viewParms = computed({
  get() {
    return store.viewParameters.users;
  }
});

const columnDefs = [
      { headerName: 'Parámetro', field: 'parameter', width: 300, sortable: true, cellRenderer: linkRenderer},
      { headerName: 'Código', field: 'code', width: 400, sortable: true, cellRenderer: linkRenderer},
      { headerName: 'Valor', field: 'value', flex: 1, sortable: true, cellRenderer: linkRenderer},
  ];

const getData = async () => {
  let parameterCode = '';
  if (viewParms.value.listId != 0 ) {
    const parameter = parameters.value.filter(p => p.id == viewParms.value.parameterId);
    if (parameter.length > 0) {
      parameterCode = parameter[0].parameter;
    }
  }
  if (viewParms.value.parameterId == null) {
    viewParms.value.parameterId = 0;
  }
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando lista de valores'});
  const params = {
    parameter: parameterCode,
    search: viewParms.value.search,
    userId: store.userInfo.userId,
    token: store.userInfo.token
  };
  await axios
  .post(`${api.value}/parameters/list`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
      }
      if (gridColumnApi && store.viewParameters.order != '') {
        gridColumnApi.api.applyColumnState({
          state: [{ colId: store.viewParameters.order, sort: store.viewParameters.orderDirection }],
          defaultState: { sort: null }
        })
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar parámetros', response.data.message);
    }
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar parámetros', error.message);
  });
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

const clearSearchPhrase = () => {
    viewParms.value.currentPage = 1;
    viewParms.value.search = '';
    searchPhrase();
}

const searchPhrase = () => {
    if (loaded.value) {
      viewParms.value.currentPage = 1;
      getData();
    }
}

const parameterFiltering = e => {
  let query = new Query().select(['parameter', 'id']);
  query = (e.text !== '') ? query.where('parameter', 'contains', e.text, true, true) : query;
  e.updateData(parameters.value, query);
}

const postSortRows = params => {
  for (const col of params.api.getColumnState()) {
    if (col.sort) {
      store.viewParameters.order = col.colId;
      store.viewParameters.orderDirection = col.sort;
    }
  }
}

const onGridReady = params => {
  gridColumnApi = params.columnApi;
  getData();
}

const add = () => {
  router.push({
    name: 'parameter',
    params: {id: 0}
  });
}

onMounted(async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando parámetros'});
  await axios
  .post(`${api.value}/parameters/parameters_list`, store.userInfo)
  .then(response => {
      if (response.data.success) {
        parameters.value = response.data.data;
        getData();
      } else {
        dialog.value.show('error', 'Error interno al cargar listado de parámetros', response.data.message);
      }
      loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogo', errorMessage);
  });
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div v-if="loaded" class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                          <b-button variant="success" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Agregar" @click="add">
                              <i class="las la-plus"></i>
                          </b-button>
                        </div>
                        <div class="col"/>
                        <div class="d-inline-block mr-1" style="width: 400px;">
                          <DropDownListComponent
                            v-model:value="viewParms.parameterId"
                            placeholder="Parámetro"
                            highlight=true
                            :dataSource="parameters"
                            :fields="{text: 'parameter', value: 'id'}"
                            :query='query'
                            allowFiltering=true
                            :filtering='parameterFiltering'
                            :showClearButton='true'
                            :change="onFilterChange"
                          />
                        </div>
                        <div class="d-inline-block" style="width: 300px;">
                          <div class="e-input-group">
                            <input type="text" v-model="viewParms.search" class="e-input" showClearButton="true" placeholder="Buscar" @keyup.enter="searchPhrase"/>
                            <span v-if="viewParms.search != ''" class="e-input-group-icon e-input-clear" @click="clearSearchPhrase"/>
                            <span class="e-input-group-icon e-input-search" @click="searchPhrase"/>
                          </div>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%; height: 600px"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                        :postSortRows="postSortRows"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style>
  @import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
</style>