<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";


export default {
  components: {
    Layout,
    PageHeader,
    flatPickr
  },
  data() {
    return {
      title: "Tablero",
      items: [
        {
          text: "Inicio",
          href: "/",
        },
        {
          text: "Tablero",
          active: true,
        },
      ],
      date: null,
      config: {
        mode: "range",
      }
    };
  },
  computed: {
    greeting() {
      const date = new Date();
      let greeting = 'Buenas noches';
      if (date.getHours() < 19) {
        greeting = 'Buenas tardes';
      } else if (date.getHours() < 12) {
        greeting = 'Buenos días';
      }
      return greeting;
    }
  },
  methods: {
    rightcolumn() {
      if (document.querySelector('.layout-rightside-col').classList.contains('d-block')) {
        document.querySelector('.layout-rightside-col').classList.remove('d-block');
        document.querySelector('.layout-rightside-col').classList.add('d-none');
      } else {
        document.querySelector('.layout-rightside-col').classList.remove('d-none');
        document.querySelector('.layout-rightside-col').classList.add('d-block');
      }
    },

    resizerightcolumn() {
      const element = document.querySelector('.layout-rightside-col');

      if (element) {
        if (window.outerWidth < 1699) {
          element.classList.remove("d-block");
          element.classList.add("d-none");
        } else {
          element.classList.add("d-block");
          element.classList.remove("d-none");
        }
      }

      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        element.classList.remove("d-block");
        element.classList.add("d-none");
      }
    },

    hiderightcolumn() {
      const element = document.querySelector('.layout-rightside-col');
      if (element.classList.contains('d-block')) {
        element.classList.remove("d-block");
        element.classList.add("d-none");
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.resizerightcolumn);
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <BRow>
      <BCol>
        <div class="h-100">
          <BRow class="mb-3 pb-1">
            <BCol cols="12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">{{ greeting }}</h4>
                  <p class="text-muted mb-0">
                    Esto tenemos para hoy.
                  </p>
                </div>
                <div class="mt-3 mt-lg-0">
                  <BRow class="g-3 mb-0 align-items-center">
                    <BCol sm="auto">
                      <div class="input-group">
                        <flat-pickr v-model="date" :config="config"
                          class="form-control border-0 fs-13 dash-filter-picker shadow"></flat-pickr>

                        <div class="input-group-text bg-secondary border-secondary text-white">
                          <i class="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </BCol>
                  </BRow>
                </div>
              </div>
            </BCol>
          </BRow>

          <BRow>
            <BCol xl="3" md="6">
              <BCard no-body class="card-animate">
                <BCardBody>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="
                          text-uppercase
                          fw-medium
                          text-muted text-truncate
                          mb-0
                        "
                      >
                        Favoritos
                      </p>
                    </div>
                  </div>
                </BCardBody>
              </BCard>
            </BCol>
          </BRow>
        </div>
      </BCol>
    </BRow>
  </Layout>
</template>
